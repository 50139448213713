<template>
  <div class="cfa-root d-flex align-items-center justify-content-center cfa-bg-secondary oauth-wrp" style="height: 100vh; background-color: #f7f8fc;">
    <form @submit.prevent="tryLogin" class="leftBlock  py-5">
        <!-- brand -->
        <div class="auth-form-header">
            <a href="/"><div class="auth-form-header-illustration mx-auto"></div></a>
            <span class="admtag">admin</span>
            <div class="auth-form-header-separator"></div>
            <span class="auth-form-header-title">Connexion</span>
            <!-- <span ><a href="/management-board" class="text-danger">Get access to dashboard</a></span> -->
        </div>
        <!-- form -->
        <div class="auth-form-content">
            <div class="mt-3">
                <modern-input @inputChange="(e)=>{this.connect_email = e}" placeholder="Votre Adresse email" errorText="Champ requis" uid="cfa-username-bloc" type="email"></modern-input>
            </div>
            <div class="mt-5">
                <modern-input @inputChange="(e)=>{this.connect_pass = e}" placeholder="Votre Mot de passe" errorText="Champ requis" uid="cfa-password-bloc" type="password"></modern-input>
            </div>
            <div class="auth-form-link mt-5 text-center">
                <a href="/password-forget" class="forgot-password"> Mot de passe oublié ? </a>
            </div>
        </div>
        <!-- button -->
        <div class="auth-form-actions">
            <button type="submit" class="accent light normal oauth-button">
                <span v-if="!waitingAPIResponse">Je me connecte</span>
                <img v-else src="@/assets/img/gif/load2.gif" alt="loagin gif" style="width: 30px;"/>
            </button>
        </div>
    </form>
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition>
  </div>
</template>

<script>
import ModernInput from '@/components/shared/input/ModernInput.vue'
// import { getAPI } from '@/axios-api.js'
import Alert from '@/components/shared/alert/Alert.vue'
import axios from "axios";
export default {
  name: 'LoginView',
  components: {
    ModernInput,
    Alert
  },
  data () {
    return {
        connect_email: '',
        connect_pass: '',
        alertMe: false,
        gAlertMessage: '',
        gAlertType: '',
        waitingAPIResponse: false
    }
  },
  methods: {
    displayError(error, type='alert-ok') {
        this.gAlertMessage = error
        this.gAlertType = type
        this.alertMe = true
        setTimeout(() => {
            this.alertMe = false
        }, 5000);
    },
    tryLogin () {
        if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.connect_email) || this.connect_pass.length < 2) {
            this.displayError('Oups ! Veuillez remplir les champs convenablement.', 'alert-no')
        }
        else{
            this.waitingAPIResponse = true
            axios.post(`${process.env.VUE_APP_BASE_URL}accounts/admin/login`,
            {
                email: this.connect_email,
                password: this.connect_pass,
            })
            .then(response => {
                if(response.status == 200) {
                    const token = response.data.data.access
                    const refresh_token = response.data.data.refresh
                    const user_data = response.data.data.user
                    const msg = response.data.message
                    sessionStorage.setItem('token', token)
                    sessionStorage.setItem('refresh_token', refresh_token)
                    sessionStorage.setItem('user', user_data.id)
                    sessionStorage.setItem('firstname', user_data.firstname)
                    sessionStorage.setItem('lastname', user_data.lastname)
                    sessionStorage.setItem('image', null)

                    //redirect to dashbaord
                    this.$emit('connected', token)
                    this.displayError(msg, 'alert-yes')
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                        let a = document.createElement('a');
                        a.href="/management-board"
                        a.click()
                        a.remove()
                    }, 700);
                }
                else {
                    const msg='Oups ! something went wrong';
                    this.displayError(msg, 'alert-no')
                    this.waitingAPIResponse = false
                }
            })
            .catch(error => {
                sessionStorage.removeItem('token')
                if (error.response) {
                    if(error.response.data.msg == "Invalid Credentials") {
                        this.displayError("Adresse email ou mot de passe incorrect(e)", 'alert-no', 10000)
                    }
                    else{
                        const msg='Invalid Credentials';
                        this.displayError(msg, 'alert-no', 10000)
                    }
                }
                else if (error.request) {
                    this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
                }
                else {
                    this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                }
                this.waitingAPIResponse = false
            })
        }
    }
  },
  mounted() {
    console.log(this.$route)
    if(this.$route.query.loginRequired && this.$route.query.loginRequired == "true") {
      this.displayError("Bienvenu ! Vous devez vous connecter pour acceéder au ce contenu.", "alert-middle", 6500);
    }
  }
}
</script>

<style scoped>
    .admtag{
        padding: -0em 1em .2em 1em;
        background-color: #06bbc4;
        color: #fefeff;
        border-radius: 6px;
        font-size: 13px;
        margin: .2em;
        position: absolute;
        top: 2.3em;
        right: .5em;
        height: 18px;
        pointer-events: none;
    }
    @media only screen and (max-width: 600px) {
        .leftBlock{
            width: 100% !important;
            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            border-top-left-radius: 0px !important;
            /*background-color: #323c55 !important;*/
            height: 100vh;
        }
        .rightBlock{
            display: none !important;
        }
    }
    .leftBlock{
        /*height: 100vh;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0em;
        min-width: 30em;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        box-shadow: 8px 0 6px #4763881a;
        overflow-y: auto;
        width: 27%;
        z-index: 10;
        background-color: #f7f8fc;
    }
    .rightBlock{
        width: 70%;
        height: 100vh;
        background-color: #f7f8fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .rightBlock img{
        margin-bottom: 1em;
    }
    .auth-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
    }
    .auth-form-header {
        display: flex;
        flex-direction: column;
        letter-spacing: .5px;
        text-align: center;
        position: relative;
    }
    .auth-form-content{
        width: 22em;
    }
    .auth-form-header .auth-form-header-illustration {
        /* width: 121px;
        height: 30px; */
        width: 181px;
        height: 40px;
        align-self: center;
        margin-bottom: 20px;
        background-image: url(@/assets/brand/spec-brand-1.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .auth-form-header .auth-form-header-separator {
        width: 200px;
        height: 1px;
        align-self: center;
        /* background: #bfcee1; */
        border-bottom: 1px solid #bfcee1;
    }
    .auth-form-header .auth-form-header-title {
        margin-top: 20px;
        margin-bottom: 30px;
        color: #476388;
        font-size: 24px;
        white-space: nowrap;
    }
    .auth-form-link .forgot-password {
        cursor: pointer;
        text-decoration: underline;
    }
    .auth-form-link a {
        color: #476388;
    }
    /* auth actions style */
    .auth-form-actions {
        display: flex;
        width: 200px;
        /*height: 90px;*/
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
    .oauth-button{
        background-color: #06bbc4;
        color: #fff;
        border: none;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        user-select: none;
        position: relative;
        display: flex;
        min-width: 150px;
        height: 34px;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border-radius: 17px !important;
        font-size: 14px;
        font-weight: 800;
        letter-spacing: .5px;
        line-height: 32px;
        text-align: center;
        transition: all .15s cubic-bezier(0,0,.2,.1);
        box-shadow: 0 0 6px #47638840;
    }
    .oauth-button:hover{
        background-color: #109ca3;
    }
    .oauth-button-light{
        background-color: #fff;
        color: #6c84a2;
        border: none;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        user-select: none;
        position: relative;
        display: flex;
        min-width: 150px;
        height: 34px;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border-radius: 17px !important;
        font-size: 14px;
        font-weight: 800;
        letter-spacing: .5px;
        line-height: 32px;
        text-align: center;
        transition: all .15s cubic-bezier(0,0,.2,.1);
        box-shadow: 0 0 6px #47638840;
    }
    .oauth-button-light:hover{
        color: #06bbc4;
    }
</style>
